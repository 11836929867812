.DayCalendarWrapper{
    position: relative;
    height: 100px;
    background-color: rgb(243, 238, 238);
    display: flex;
    justify-content: center;
    align-items: center;

    .DayCalendar{
        position: absolute;
        top: 0;
        right: 10px;
        font-weight: bold;
    }

    .WeekendDay{
        position: absolute;
        top: 0;
        left: 10px;
        color: red;
        font-size: 13px;
    }
}