.StudentWrapper{
    background: rgb(215,215,218);
    background: linear-gradient(90deg, rgba(215,215,218,1) 0%, rgba(109,160,187,1) 45%, rgba(224,224,224,1) 100%);
    border-radius: 5px;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
        color: #fff;
    }

    p {
        color: #fff;
    }
}