.agency-dashboard{

    .divider{
        background: #fff;
        height: 0.1px;
        margin: 15px 0;
    }

    .agency-sidebar{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 60px;
        background: #11101d;
        padding-top: 25px;
        transition: all 0.5s ease;

        .logo_content{
            color: #fff;
            text-align: center;
        }

        .logo{
            display: none;
            opacity: 0;
        }

        .toggle-icon{
            margin-top: 0px;
            padding-top: 0px;
        }
    
        ul{
            margin-top: 50px;
            padding: 0;
        }
    
        ul li{
            position: relative;
            height: 50px;
            width: 100%;
            margin: 0 5px;
            list-style: none;
            line-height: 50px;
        }
    
        ul li a{
            color: #fff;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: all 0.4s ease;
            border-radius: 12px;
        }
    
        ul li a:hover{
            color: #11101d;
            background: #fff;
        }

        .activeLink {
            color: #11101d;
            background: #fff;
        }

        .link_name{
            display: none;
            opacity: 0;
        }
    
        .icon{
            height: 50px;
            min-width: 50px;
            border-radius: 12px;
            line-height: 50px;
            text-align: center;
        }

        .icon sup{
            font-size: 11px;
            padding: 2px;
            border-bottom: 1px solid #fff;
            border-radius: 50%;
        }

        .tooltip{
            position: absolute;
            left: 122px;
            top: 0;
            transform: translate(-50%, -50%);
            border-radius: 6px;
            line-height: 35px;
            text-align: center;
            height: 35px;
            width: 122px;
            background: #fff;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            transition: 0s;
            opacity: 0;
            pointer-events: none;
            z-index: 999 !important;
        }

        ul li:hover .tooltip{
            transition: all 0.5s ease;
            top: 50%;
            opacity: 1;
            z-index: 999 !important;
        }
    }

    .agency-sidebar-active{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 240px;
        padding: 25px 15px;
        background: #11101d;
        transition: all 0.5s ease;

        .logo_content{
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .logo{
            opacity: 1;
        }

        .toggle-icon{
            left: 90%
        }
    
        ul{
            margin-top: 50px;
            padding: 0;
        }
    
        ul li{
            position: relative;
            height: 50px;
            width: 100%;
            margin: 0 5px;
            list-style: none;
        }
    
        ul li a{
            color: #fff;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: all 0.4s ease;
            border-radius: 12px;
        }
    
        ul li a:hover{
            color: #11101d;
            background: #fff;
        }

        .activeLink {
            color: #11101d;
            background: #fff;
        }

        .link_name{
            display: block;
        }
    
        .icon{
            height: 50px;
            min-width: 50px;
            border-radius: 12px;
            line-height: 50px;
            text-align: center;
        }
    }
}

.agency-topbar{
    background: #5191FA;
}

.agency-container-active{
    position: absolute;
    width: calc(100% - 240px);
    left: 240px;
    transition: all 0.5s ease;
}

.agency-container{
    position: absolute;
    width: calc(100% - 60px);
    left: 60px;
    transition: all 0.5s ease;
}

.logout-button{
    color: #fff;
    margin-left: 20px;
    padding-top: 20px;
}