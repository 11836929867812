html {
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6 {
  color: #000;
  padding: 0 !important;
  margin: 0 !important;
}

* {
  letter-spacing: 1.5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-title{
  font-weight: bold;
  font-size: 32px;
}

.main-subtitle{
  font-weight: 400;
  font-size: 13px;
}

.on-hover {
  cursor: pointer;
}

table tr td{
  padding: 5px 10px !important;
}


table tr th{
  padding: 5px 10px !important;
}


@media print {
  body * {
    visibility: hidden;
  }
  #printableArea, #printableArea * {
    visibility: visible;
  }
  #printableArea {
    position: absolute;
    left: 0;
    top: 0;
  }
}