.ExpandComponentWrapper {
    margin-bottom: 20px;

    .Header{
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .Body{
        padding: 10px 30px;
    }

    .StudentItemWrapper{
        padding: 5px 10px;
        cursor: pointer;
    }

    .StudentItemWrapper:hover{
        background-color: #1894d7;
        border-radius: 3px;
        p{
            color: #fff;
        }
    }

    .StudentItemWrapperActive{
        background-color: #1894d7;
        border-radius: 3px;
        p{
            color: #fff;
        }
    }
}