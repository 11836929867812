.SettingsWrapper {

    .SettingsCardWrapper{
        background-color: rgb(243, 238, 238);
        border-radius: 6px;
        padding: 10px 15px;
        height: 200px;
        cursor: pointer;

        .Title{
            font-weight: bold;
        }

        .Description{
            margin-top: 20px !important;
            font-size: 13px;
        }
    }
}

.MonthCard{
    background-color: rgb(243, 238, 238);
    border-radius: 6px;
    padding: 5px 10px;
    text-align: center;
}

.CategoryCard{
    background-color: rgb(243, 238, 238);
    border-radius: 6px;
    padding: 5px 10px;
    text-align: center;

    p {
        font-size: 14px;
    }
}

.CategoryWithModulesWrapper{
    background-color: rgb(243, 238, 238);
    border-radius: 6px;
    padding: 20px 30px;
    margin-bottom: 20px;

    .ModuleWrapper{
        background-color: #fff;
        border-radius: 6px;
        padding: 5px 10px;
        text-align: center;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
    }
}