.LoginWrapper{
    height: 100vh;
}

.LoginCard{
    width: 600px;
    padding: 40px 0 40px 0;
}


@media only screen and (max-width: 800px) {
    .LoginCard {
        width: 100% !important;
    }
  }