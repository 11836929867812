.EventWrapper{
    border: 1px solid rgb(207, 205, 205);
    border-radius: 6px;
    padding: 10px 20px;
    text-align: center;
}


.InfoEventInvitedWrapper{
    border: 1px solid rgb(207, 205, 205);
    border-radius: 6px;
    padding: 5px 10px;
    margin-bottom: 10px;
}